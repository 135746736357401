import { CONSTANTS } from '../types';

export const isFormType = (type: string) => {
  return Object.keys(CONSTANTS.GENERATOR.FORM).includes(type);
};

export const isOptionsFieldType = (type: string) =>
  type === 'Select' || type === 'Checkbox' || type === 'RadioButtons';

/**
 * Mutates the array!
 */
export const toggleValueInArray = (array: any[], value: string | number) => {
  let index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    do {
      array.splice(index, 1);
      index = array.indexOf(value);
    } while (index !== -1);
  }
};

/**
 * At least 8 chars, 1 big, 1 small letter, 1 number and 1 Sonderzeichen
 */
export const isStrongPassword = (password: string) =>
  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(password);
