/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TValidFieldTypes } from './forms';

// helpers
export interface StringAsKey {
  [key: string]: string;
}

// DND
export type TDroppedItemType = {
  type: string;
  field: TValidFieldTypes;
  label: string;
  index?: number;
};

export type TWithChildren = {
  children: React.ReactNode;
};

interface IConstants {
  IS_PROD: boolean;
  BACKEND_URL: string;
  FRONTEND_HOME_URL: string;
  COMPANY_NAME: string;
  DND_ITEM_TYPES: StringAsKey;
  GENERATOR: {
    FORM: StringAsKey;
    COMMON: StringAsKey;
  };
}

export const CONSTANTS: IConstants = {
  IS_PROD: process.env.NODE_ENV === 'production',
  BACKEND_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL!
      : process.env.REACT_APP_DEV_API_URL!,
  FRONTEND_HOME_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_APP_URL!
      : 'http://localhost:3000',

  COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME!,

  DND_ITEM_TYPES: {
    ADD_FIELD: 'add_field',
    ORDER_FIELD: 'order_field',
    SURVEY_QUESTION: 'survery_question',
  },
  GENERATOR: {
    FORM: {
      Input: 'Textfeld einzeilig',
      TextArea: 'Textfeld mehrzeilig',
      Select: 'Auswahlmenü',
      RadioButtons: 'Auswahlfelder',
      Checkbox: 'Ankreuzfelder',
    },
    COMMON: {
      Headline: 'Überschrift',
      Text: 'Text',
      Spacer: 'Abstandshalter',
    },
  },
};
