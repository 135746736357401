import { SetState } from 'zustand';
import { IStore, IUser, IFullUser } from '../types';
import { AxiosResponse } from 'axios';

export const togglePending = (set: SetState<IStore>) => {
  set((state) => ({ isPending: !state.isPending }));
};

export const updateUser = (
  response: AxiosResponse<IUser | IFullUser>,
  set: SetState<any>
) => {
  try {
    const isValidUser = response.data.name;

    set(() => ({
      user: isValidUser ? response.data : null,
      isAuthenticated: isValidUser,
      isAdmin: response.data?.role === 3,
    }));
  } catch (err) {
    console.log(err);
  }
};

export const replaceNewLinesWithComma = (stringWithBr: string) =>
  stringWithBr.replace(/\n/g, ',');

export const replaceCommaWithNewLines = (stringWithLines: string) =>
  stringWithLines.replace(/,/g, '\n');
